import React, { Component } from "react"
import { connect } from "react-redux"

import { setPath } from "actions/path"
import { Post } from "utils/axios"
import { requestError } from "utils/requestHandler"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      email: "",
      showResetPwAlert: false,
      errors: []
    }

    load = (param) => this.setState({ loading: param })
    onChangeResetPwHOC = (key, val) => this.setState({ [key]: val })

    sendResetPwEmail = (dataToSubmit) => {
      Post(
        `/reset_password_requests`,
        {...dataToSubmit, platform: "dashboard"},
        this.sendResetPwEmailSuccess,
        this.sendResetPwEmailError,
        this.load
      )
    }
    sendResetPwEmailSuccess = () => {
      this.setState({ showResetPwAlert: true })
    }
    sendResetPwEmailError = (error) => {
      if( error.status === 404 ){
        this.props.history.push('/login')
        requestError('Unable to reset password', 'Error' )
      } else {
        requestError( error.message, 'Error' )
      }
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          showResetPwForm={this.state.showResetPwForm}
          onChangeResetPwHOC={this.onChangeResetPwHOC}
          sendResetPwEmail={this.sendResetPwEmail}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state })
  return connect(mapStateToProps, {
    setPath
  })(WithHOC)
}
export default HOC