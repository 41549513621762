import React, { useEffect, useState } from "react"
import { BsCheckCircleFill } from "react-icons/bs"
import { BiErrorCircle } from "react-icons/bi"
import { useLocation } from 'react-router-dom'
import { Tooltip } from "antd"
import _ from "lodash"

import TopBar from "components/TopBar"
import CustomIcon from "components/Icon"
import CustomCard from "components/Card"
import CustomAlert from "components/Alert"
import CustomButton from "components/Button"
import Breadcrumb from "components/Breadcrumb"
import LoadingOverlay from "components/Loading"
import FormInput from "components/Input/formInput"
import TACContent from "./TAC"

import NewPwHOC from "./actions"

const NewPassword = props => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()
  const [showTokenError, setShowTokenError] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordFullfilled, setPasswordFullfilled] = useState({
      charLength: false,
      specialChar: false,
      number: false,
      upperCase: false,
      lowerCase: false
    })

  useEffect(() => {
    const queryToken = query.get("token")
    if (_.isEmpty(queryToken) || queryToken === null || queryToken === 'null' || queryToken.length < 1) {
      setShowTokenError(true)
    }

    props.setPath([{ label: "Reset Password" }])
  }, [])

  useEffect(() => {
    let temp = _.cloneDeep(passwordFullfilled)
    temp.charLength = props.newPassword.length >= 8 && props.newPassword.length <= 16
    temp.specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(props.newPassword)
    temp.number = /[0-9]+/.test(props.newPassword)
    temp.upperCase = /[A-Z]+/.test(props.newPassword)
    temp.lowerCase = /[a-z]+/.test(props.newPassword)
    setPasswordFullfilled(temp)
  }, [props.newPassword])

  const renderInstruction = () => {
    const passwordInstruction = [
      { label: '8 - 16 Characters', status: passwordFullfilled.charLength },
      { label: '1 Special Character', status: passwordFullfilled.specialChar },
      { label: '1 Number', status: passwordFullfilled.number },
      { label: '1 Uppercase', status: passwordFullfilled.upperCase },
      { label: '1 Lowercase', status: passwordFullfilled.lowerCase }
    ]

    return (
      <div className="d-flex flex-column">
        {passwordInstruction.map((item, index) => (
          <span key={index} style={{fontSize: 12, color: '#dddddd'}}>
            <BsCheckCircleFill style={{
              marginRight: '8px',
              borderRadius: '50%',
              color: item.status ? 'green' : '#dddddd',
              backgroundColor: item.status ? 'white' : '#dddddd'
            }} />
            {item.label}
          </span>
        ))}
      </div>
    )
  }

  return (
    <>
      <TopBar />
      <Breadcrumb pathData={[]} />
      <div className="eon-main-inner">
        <div className="d-flex flex-column" style={{ padding: "32px 0px", textAlign: "center" }}>
          <span className="text-lg text-grey font-weight-heavy">Reset Password</span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <CustomCard
            mode={"default"}
            containerStyle={{ padding: "24px", gap: "12px", width: "450px" }}>
            <CustomAlert color={"danger"} isOpen={showTokenError}>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center text-sm font-weight-light">
                  <BiErrorCircle style={{ fontSize: "24px", marginRight: "8px" }}/>
                  Token is not found or invalid!
                </div>
              </div>
            </CustomAlert>
            {props.showOTP && <TACContent {...props} token={query.get("token")} />}
            {!props.showOTP && (
              <>
                <div className="d-flex justify-content-between">
                  <label className="d-flex align-items-center mb-0">
                    <span>New Password</span>
                    <Tooltip title={renderInstruction()}>
                      <BsCheckCircleFill style={{
                        marginLeft: '8px',
                        borderRadius: '50%',
                        color: _.values(passwordFullfilled).every(item => item) ? 'green' : '#dddddd',
                        backgroundColor: _.values(passwordFullfilled).every(item => item) ? 'white' : '#dddddd'
                      }} />
                    </Tooltip>
                  </label>
                  <CustomButton
                    size={"sm"}
                    color={"borderless"}
                    onClick={() => setShowNewPassword((show) => !show)}>
                    {showNewPassword ?
                      <CustomIcon antIcon="EyeOutlined" /> :
                      <CustomIcon antIcon="EyeInvisibleOutlined" />}
                  </CustomButton>
                </div>
                <FormInput
                  type={showNewPassword ? "text" : "password"}
                  context="password"
                  value={props.newPassword}
                  placeholder={"Enter New Password"}
                  errors={props.errors}
                  onChangeData={val => props.onChangeNewPwHOC("newPassword", val)} />

                <div className="d-flex justify-content-between">
                  <label className="d-flex align-items-center mb-0">Confirm New Password</label>
                  <CustomButton
                    size={"sm"}
                    color={"borderless"}
                    onClick={() => setShowConfirmPassword((show) => !show)}>
                    {showConfirmPassword ?
                      <CustomIcon antIcon="EyeOutlined" /> :
                      <CustomIcon antIcon="EyeInvisibleOutlined" />}
                  </CustomButton>
                </div>
                <FormInput
                  type={showConfirmPassword ? "text" : "password"}
                  context="password_confirmation"
                  value={props.confirmNewPassword}
                  placeholder={"Reenter New Password"}
                  errors={props.errors}
                  onChangeData={val => props.onChangeNewPwHOC("confirmNewPassword", val)}
                />

                <CustomButton
                  buttonClass={"full-width-button mt-2"}
                  size={"lg"}
                  color={"black"}
                  disabled={
                    props.newPassword.length < 1 ||
                    props.confirmNewPassword.length < 1 ||
                    props.newPassword !== props.confirmNewPassword ||
                    !_.values(passwordFullfilled).every(item => item) ||
                    showTokenError
                  }
                  onClick={() => props.triggerOTP(query.get("token"))}>
                  Reset Password
                </CustomButton>
              </>
            )}
          </CustomCard>
        </div>
      </div>
      {props.loading && <LoadingOverlay />}
    </>
  )
}

export default NewPwHOC(NewPassword)