import React from "react"
import _ from "lodash"
import { Row, Col } from "antd"

import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"

import "./index.scss"

const TAC = props => {

  const onChangeTAC = (val, index) => {
    let temp = _.cloneDeep(props.tac)
    temp[index] = val
    props.onChangeNewPwHOC("tac", temp)

    if( index < 3 && val !== '' ){
      let element = document.getElementById(`tac-${index + 1}`)
      element.focus()
    }
  }

  const onKeyDown = (e, index) => {
    if( e.keyCode === 8 && e.target.value === '' && index > 0 ){
      let element = document.getElementById(`tac-${index - 1}`)
      element.focus()
    }
  }

  const onToggleSetPassword = () => {
    if( _.values(props.tac).every(val => val !== '') ){
      let tac = _.values(props.tac).join('')
      props.setNewPassword({
        password: props.newPassword,
        password_confirmation: props.confirmNewPassword,
        otp: tac,
        token: props.token
      }, props.token)
    } else {
      let errors = []
      errors.push({field: 'tac', message: 'Please fill up all TAC fields.'})
      props.onChangeNewPwHOC("errors", errors)
    }
  }

  return (
    <>
      <div className="d-flex flex-column">
        <span className="text-lg text-grey font-weight-heavy">OTP Verification</span>
        <span className="text-sm text-light-grey font-weight-light">
          Please enter the OTP sent to +60{props.userAccount.phone_no?.substring(0,2)}***{props.userAccount.phone_no?.substring(5)}.
        </span>
      </div>
      <Row gutter={[16, 16]}>
        { props.tac.map((val, index) => (
          <Col span={6} key={index}>
            <FormInput
              id={`tac-${index}`}
              inputClass="tac-input-field"
              type="text"
              context=""
              value={val || ''}
              maxLength={1}
              placeholder={"-"}
              required={false}
              onKeyDown={(e) => onKeyDown(e, index)}
              onChangeData={(val) => onChangeTAC(val, index)}
            />
          </Col>
        )) }
      </Row>
      <span className="text-danger">
        {props.errors?.length > 0 &&
          _.find(props.errors, (obj) => obj.field === 'tac')?.message}{" "}
      </span>
      <div className="d-flex flex-column w-100">
        <CustomButton
          buttonStyle={{ marginBottom: "10px" }}
          buttonClass={"full-width-button"}
          size={"lg"}
          color={"black"}
          onClick={() => onToggleSetPassword()}
          children={"Confirm"}
        />
      </div>
    </>
  )
}

export default TAC