import React, { Component } from "react"
import { connect } from "react-redux"
import Axios from "axios"

import { setPath } from "actions/path"
import { requestError, requestSuccess } from "utils/requestHandler"
import getDomainURL from "utils/api"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      newPassword: "",
      confirmNewPassword: "",
      tac: ["", "", "", ""],
      errors: [],
      showOTP: false,
      userAccount: {},
    }

    onChangeNewPwHOC = (key, val) => this.setState({ [key]: val })

    setNewPassword = (dataToSubmit, token) => {
      this.setState({ loading: true }, () => {
        Axios.put(
          `${getDomainURL()}/api/reset_password`,
          dataToSubmit,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then(() => {
            this.setState({ loading: false })
            this.props.history.push("/login")
            requestSuccess(
              "Password reset successfully. Please proceed to login."
            )
          })
          .catch((err) => {
            this.setState({ loading: false })
            requestError(err?.response?.data?.message ?? "Oops, something happened. Please try again.", "Error")
          })
      })
    }

    triggerOTP = (token) => {
      this.setState({ loading: true }, () => {
        Axios.post(
          `${getDomainURL()}/api/set_password/otp`,
          { token: token, case: 'reset_password' },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((res) => {
          this.setState({ loading: false, showOTP: true, userAccount: res.data.data })
          requestSuccess("OTP sent successfully.")
        }).catch((err) => {
          this.setState({ loading: false })
          requestError(err?.response?.data?.message ?? "Oops, something happened. Please try again.", "Error")
        })
      })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          triggerOTP={this.triggerOTP}
          setNewPassword={this.setNewPassword}
          onChangeNewPwHOC={this.onChangeNewPwHOC}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state })
  return connect(mapStateToProps, {
    setPath
  })(WithHOC)
}
export default HOC