import React, { useEffect } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"

import Login from "containers/Login"
import Registration from "containers/Register"
import ResetPassword from "containers/ResetPassword"
import NewPassword from "containers/NewPassword"
import Dashboard from "containers/Dashboard"
import Administration from "containers/Administration"

import { getItem } from "utils/tokenStore"
import { getProfile } from 'actions/profile';
import { setLastViewURL } from "reducers/lastViewURL";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
)

function CustomRouter(props) {
  useEffect(()=>{
    if( getItem( "EON_ERP_ACCESS_TOKEN" ) ){
      const lasView = props.data.LastViewReducer?.last_view_url;

      props.getProfile();
      props.setLastViewURL("")
      if (['super admin', 'admin']?.includes(props.data.ProfileReducer.profile.role)) {
        if (lasView) {
          return props.history.push(lasView);
        }
      } else {
        if (lasView) {
          return props.history.push(lasView);
        }
      }
    }
  },[])

  return (
    <Switch>
      <Route exact path="/login" component={Login} initialPath />
      <Route exact path="/registration" component={Registration} />
      <Route exact path="/reset-password-request" component={ResetPassword} />
      <Route exact path="/reset-password" component={NewPassword} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/admin" component={Administration} />
      <Redirect from={"/"} to={"/login"} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ data: state })
export default compose(
  connect(mapStateToProps, { getProfile, setLastViewURL }), 
  withRouter
)(CustomRouter)